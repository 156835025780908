<template>
  <div class="sidebar" :class="{ collapsed: isCollapsed }">
    <div class="toggle-button" @click="toggleSidebar">
      <i class="fas" :class="isCollapsed ? 'fa-chevron-right' : 'fa-chevron-left'"></i>
    </div>
    <ul>
      <li class="sidebar-item" v-for="item in sidebarItems" :key="item.id" @mouseover="showItemText(item.id)" @mouseleave="hideItemText(item.id)">
        <router-link :to="item.route" class="sidebar-link">
          <i :class="item.icon"></i>
          <span class="sidebar-item-text">{{ item.text }}</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isCollapsed: true,
      sidebarItems: [
        { id: 1, route: '/Research', icon: 'fa fa-search', text: 'Research' },
        { id: 2, route: '/Wishlist', icon: 'fas fa-heart', text: 'My Wishlist' },
        { id: 3, route: '/PendingCustomer', icon: 'fa fa-clock', text: 'Pending Auction' },
        { id: 4, route: '/ClosingCustomer', icon: 'fa fa-window-close', text: 'Closing Auction' },
        { id: 5, route: '/WonCustomer', icon: 'fa fa-trophy', text: 'Won Auction' },
        { id: 6, route: '/ArrivedCustomer', icon: 'fa fa-location-arrow', text: 'Arrived Auction' },
        { id: 7, route: '/DeliveredCustomer', icon: 'fa fa-truck', text: 'Delivered Auction' },
      ],
      hoveredItem: null,
    };
  },
  methods: {
    toggleSidebar() {
      this.isCollapsed = !this.isCollapsed;
    },
    showItemText(itemId) {
      this.hoveredItem = itemId;
    },
    hideItemText(itemId) {
      if (this.hoveredItem === itemId) {
        this.hoveredItem = null;
      }
    },
  },
};
</script>

<style scoped>
.sidebar {
  width: 80px;
  background-color: #f0f0f0;
  transition: width 0.3s ease;
}

.sidebar.collapsed {
  width: 40px;
}

.toggle-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  cursor: pointer;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.sidebar-item {
  display: flex;
  align-items: center;
  padding: 10px;
}

.sidebar-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #333;
  transition: background-color 0.3s ease;
}

.sidebar-link:hover {
  background-color: #e1e1e1;
}

.sidebar-item-text {
  margin-left: 10px;
  transition: opacity 0.3s ease;
  opacity: 0;
}

.sidebar-item:hover .sidebar-item-text,
.sidebar-item:hover .sidebar-link.router-link-active .sidebar-item-text {
  opacity: 1;
}
</style>
