<template>
    <div class="dashboard">
      <sidebar></sidebar>
  
      <div class="dashboard-content">
        <div class="dashboard-section">
          <div class="dashboard-card">
            <h2>Wishlisted Auctions</h2>
            <p>1</p>
          </div>
  
          <div class="dashboard-card">
            <h2>Pending Auctions</h2>
            <p>0</p>
          </div>
  
          <div class="dashboard-card">
            <h2>Won Auctions</h2>
            <p>3</p>
          </div>
  
          <div class="dashboard-card">
            <h2>Delivered Auctions</h2>
            <p>5</p>
          </div>
        </div>
  
        <div class="dashboard-section">
          <h2>Basic Details</h2>
          <!-- Add the code for Basic Details section here -->
          <div class="basic-details-section">
            <div class="edit-button">
              <button>Edit</button>
            </div>
            <div class="details-field">
              <p><strong>Full Name:</strong> Rajiv C</p>
              <p><strong>Email:</strong> rajivCustomer@intertoons.com</p>
              <p><strong>Phone:</strong> <img src="canada-flag.png" alt="Canada Flag"> +1 8606366422</p>
            </div>
          </div>
        </div>
  
        <div class="dashboard-section">
          <h2>Change Password</h2>
          <!-- Add the code for Change Password section here -->
          <div class="change-password-section">
            <div class="password-field">
              <label for="current-password">Current Password</label>
              <input type="password" id="current-password">
            </div>
            <div class="password-field">
              <label for="new-password">New Password</label>
              <input type="password" id="new-password">
            </div>
            <div class="password-field">
              <label for="confirm-password">Confirm Password</label>
              <input type="password" id="confirm-password">
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import Sidebar from './Sidebar.vue';
  
  export default {
    components: {
      Sidebar
    },
  }
  </script>
  
  <style scoped>
  .dashboard {
    display: flex;
  }
  
  .dashboard-content {
    flex: 1;
    padding: 20px;
  }
  
  .dashboard-section {
    margin-bottom: 20px;
  }
  
  .dashboard-card {
    background-color: #f1f1f1;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  .dashboard-card h2 {
    font-size: 16px;
    margin-bottom: 5px;
  }
  
  .dashboard-card p {
    font-size: 20px;
    font-weight: bold;
    margin: 0;
  }
  
  .basic-details-section {
    display: flex;
    align-items: center;
  }
  
  .edit-button {
    margin-right: 10px;
  }
  
  .details-field p {
    margin: 5px 0;
  }
  
  .change-password-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .password-field {
    margin-bottom: 10px;
  }
  
  .password-field label {
    display: block;
    margin-bottom: 5px;
  }
  </style>
  